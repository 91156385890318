<template>
  <div class="course-user-page">
    <transition name="component-fade" mode="out-in">
      <div v-if="loading" key="loader" class="f-center h-100">
        <div>
          <Loader />
        </div>
      </div>

      <div v-else class="course-user-page__wrapper">
        <div class="course-user-page__course-info course-info">
          <div class="course-info__bg">
            <img :src="courseImage" alt="Изображение курса" />
          </div>
          <div class="course-info__wrapper container">
            <div class="course-info__info-status info-status">
              <div class="info-status__back-to-map" @click="backButton">
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11 5H1" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M5 9L1 5L5 1"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {{ backButtonLabel }}
              </div>
              <div class="info-status__title">
                {{ $t('header.course') }} {{ indexOfPlanet + 1 }}.
                {{ $t(`planets.${config.planets[indexOfPlanet + 1]}`) }}
              </div>
              <div class="info-status__description">{{ courseStatus }}</div>
            </div>
            <div class="course-info__info-progress" v-if="!isShowContent && course.max_score !== 0">
              <LmsProgressBarLarge
                :maxScore="course.max_score"
                :score="course.statistic.score"
                :progressStatus="course.statistic.status"
              />
            </div>
            <TagFilter v-if="!isShowContent &&  courseTags.length" class="course-info__tags"
                       :tags="courseTags" />
            <div class="course-info__main-info">
              <div class="course-info__title">
                {{ courseName }}
              </div>
              <div v-if="!isShowContent" class="course-info__description">
                {{ courseDescription }}
              </div>
            </div>
          </div>
        </div>

        <div class="course-user-page__content-wrapper container pt-0 pb-0">
          <div>
            <div class="course-user-page__action-button">
              <AddButton :title="buttonLabel" @click="openViewer" />
              <DownloadCertBtn v-if="certificateConfig" :certificate="personalizedCertificate" />
            </div>
            <div class="course-user-page__full-description">
              <EditorJsComponent :initial-content="courseFullDescription" :readOnly="true" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StudentService from '@/services/student.service';
import Loader from '@/components/Loader/Loader.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import deviceMixin from '@/mixins/device.mixin';
import EditorJsComponent from '@/components/EditorJsComponent.vue';
import LmsProgressBarLarge from '@/components/LmsProgressBarLarge.vue';
import DownloadCertBtn from '@/components/DownloadCertBtn.vue';
import TagService from '@/services/tag.service';
import TagFilter from '@/components/Tag/TagFilter.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'CourseUser',
  components: {
    TagFilter,
    Loader,
    AddButton,
    EditorJsComponent,
    LmsProgressBarLarge,
    DownloadCertBtn,
  },
  mixins: [deviceMixin],
  data: () => ({
    loading: true,
    course: null,
    indexOfPlanet: null,
    isShowContent: false,
    isCertificateAvailable: false,
    certificateConfig: null,
    courseTags: [],
  }),
  async created() {
    const groupIdOfLevelOne = this.$route.query?.groupIdOfLevelOne || null;
    const groupIdOfLevelTwo = this.$route.query?.groupIdOfLevelTwo || null;
    this.isShowContent = this.$route.query?.isShowContent === 'true';
    this.setGroupIdOfLevelOne(groupIdOfLevelOne);
    this.setGroupIdOfLevelTwo(groupIdOfLevelTwo);

    const { programId, courseId } = this.$route.params;
    const result = await this.fetchCourseContent({ programId, courseId });
    this.course = result.activity;

    await StudentService.getProgramCourses(programId)
      .then(({ data }) => {
        this.indexOfPlanet = data.findIndex((course) => course._id === courseId);
      })
      .catch(console.log);
    if (this.course.statistic.status === ActivityStatuses.done) {
      this.getCourseCertificate(courseId);
    }

    this.courseTags = await TagService.getTagsByStudent(programId, courseId).catch(console.log);

    this.loading = false;
  },
  computed: {
    ...mapGetters({ config: 'config/getAppConfig' }),
    ...mapGetters({ user: 'auth/getUser' }),
    ...mapGetters('courseContentStudent', [
      'content',
      'contentLevel',
      'groupIdOfLevelOne',
      'groupIdOfLevelTwo',
    ]),
    courseName() {
      return this.course?.name;
    },
    courseDescription() {
      return this.course?.description;
    },
    courseFullDescription() {
      return JSON.parse(this.course?.full_description || '{}');
    },
    courseStatus() {
      if (this.course.statistic.status === ActivityStatuses.done) {
        return this.$t('label.explored');
      }
      if (this.course.statistic.status === ActivityStatuses.notStarted) {
        return this.$t('label.notExplored');
      }

      return this.$t('label.researchInProgress');
    },
    courseImage() {
      let image = require('@/assets/images/png/current-course-background.png');
      if (this.course?.meta?.imageHighres) {
        image = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.course.meta.imageHighres
            : this.$device.isTablet
              ? this.course.meta.imageMidres
              : this.course.meta.imageLowres
        }`;
      }
      return image;
    },
    backButtonLabel() {
      if (!this.isShowContent) {
        return this.$t('links.programMap');
      }
      return this.$t('buttonLabels.back');
    },
    buttonLabel() {
      if (this.content.activity.statistic.status === ActivityStatuses.doing) {
        return this.$t('buttonLabels.continueLearning');
      }
      return this.$t('buttonLabels.startLearning');
    },
    combined() {
      return {
        groupIdOfLevelOne: this.groupIdOfLevelOne,
        groupIdOfLevelTwo: this.groupIdOfLevelTwo,
        isShowContent: this.isShowContent,
      };
    },
    personalizedCertificate() {
      const userCert = this.certificateConfig;
      // TODO: add case if no text
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'fio',
      ).attrs.text = `${this.user.first_name} ${this.user.last_name}`;
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'score',
      ).attrs.text = `${this.course.statistic.score} / ${this.course.max_score}`;
      userCert.children[0].children.find(
        (el) => el.className === 'Text' && el.attrs.name === 'name',
      ).attrs.text = this.course.name;
      return userCert;
    },
  },
  methods: {
    ...mapActions('courseContentStudent', [
      'fetchCourseContent',
      'setGroupIdOfLevelOne',
      'setGroupIdOfLevelTwo',
    ]),
    backButton() {
      if (this.contentLevel === 2) {
        return this.setGroupIdOfLevelTwo(null);
      }
      if (this.contentLevel === 1) {
        return this.setGroupIdOfLevelOne(null);
      }
      if (this.contentLevel === 0 && this.isShowContent) {
        this.$router.replace({
          name: 'CourseUser',
          query: {
            isShowContent: false,
          },
        });
        return (this.isShowContent = !this.isShowContent);
      }
      return this.$router.push(`/program-user/${this.$route.params.programId}`);
    },
    getCourseCertificate(id) {
      StudentService.getCertificate(id)
        .then(({ data }) => {
          this.isCertificateAvailable = true;
          this.certificateConfig = data.config;
        })
        .catch((error) => {
          if (error.response?.status !== 404) {
            console.log(error);
          }
        });
    },
    openViewer() {
      this.$router.push({ name: 'Viewer' });
    },
  },
  watch: {
    combined: {
      handler(value) {
        const queryLevelOne = this.$route.query?.groupIdOfLevelOne || null;
        const queryLevelTwo = this.$route.query?.groupIdOfLevelTwo || null;
        const isShowContent = this.$route.query?.isShowContent === 'true';

        if (
          value?.groupIdOfLevelOne === queryLevelOne
          && value?.groupIdOfLevelTwo === queryLevelTwo
          && value.isShowContent === isShowContent
        ) {
          return;
        }

        this.$router.replace({
          name: 'CourseUser',
          query: {
            isShowContent: value.isShowContent,
            groupIdOfLevelOne: value?.groupIdOfLevelOne,
            groupIdOfLevelTwo: value?.groupIdOfLevelTwo,
          },
        });
      },
      deep: true,
    },
  },
};
</script>
