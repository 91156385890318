<template>
  <div class="progress-large">
    <div class="progress-large__left">
      <div class="progress-large__labels">
        <div class="progress-status"
        :class="{
          'progress-status--doing': progressStatus === ActivityStatuses.doing,
          'progress-status--done': progressStatus === ActivityStatuses.done,
          'progress-status--not-started': progressStatus === ActivityStatuses.notStarted,
        }"
        >{{status}}</div>
        <div class="progress-counter">
          {{`${score} / ${maxScore} `}}{{$tc('label.pointsPluralRod', maxScore)}}
        </div>
      </div>
      <div class="lms-progress-bar"
      :class="{
          'lms-progress-bar--doing': progressStatus === ActivityStatuses.doing,
          'lms-progress-bar--done': progressStatus === ActivityStatuses.done,
          'lms-progress-bar--not-started': progressStatus === ActivityStatuses.notStarted,
        }"
      >
        <b-progress :value="percent" :max="100"></b-progress>
      </div>
    </div>
    <div class="progress-large__right">
      <span>
        {{percent}}%
      </span>
    </div>
  </div>
</template>

<script>
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'LmsProgressBarLarge',
  props: {
    maxScore: {
      type: Number,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    progressStatus: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    ActivityStatuses,
  }),
  computed: {
    percent() {
      return Math.round((this.score / this.maxScore) * 100) || 0;
    },
    status() {
      if (this.progressStatus === ActivityStatuses.doing) {
        return this.$t('label.inProgress');
      }
      if (this.progressStatus === ActivityStatuses.done) {
        return this.$t('label.passed');
      }
      return this.$t('label.notStarted');
    },
  },
};
</script>
